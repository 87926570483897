<template>
  <div id="app">
    <AppContainer>
      <AppBody />
    </AppContainer>
  </div>
</template>

<script>
import AppContainer from "../components/AppContainer.vue";
import AppBody from "../components/AppBody.vue";

export default {
  name: "App",
  components: {
    AppContainer,
    AppBody,
  },
};
</script>

<!-- font-family: "Hack", monospace; -->
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

body {
  background: #ffff;
}
@font-face {
  font-family: "3270";
  src: url("@/assets/fonts/3270.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
</style>
