import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/home.css"; // Import your fonts.css file
//import "nes.css/css/nes.min.css";
import { createHead } from "@vueuse/head";

const head = createHead();

const app = createApp(App);

// Add the event for Prerender SPA Plugin
app.mixin({
  mounted() {
    document.dispatchEvent(new Event("render-event")); // Notify the prerenderer that rendering is complete
  },
});

// Use plugins and mount the app
app.use(head).use(router).use(store).mount("#app");
