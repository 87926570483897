<template>
  <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
    <div>
      <div style="text-align: center" class="slideshow-container">
        <!-- Toggle button to switch to BONOS template -->
        <nav class="subheader">
          <ul class="nes-list">
            <li>
              <a
                :href="getDynamicUrl('/finanzas')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                CENTRAL
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/MERVAL')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                MERVAL
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/BONOS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                BONOS
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/CEDEARS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                CEDEARS
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/TASAS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                TASAS
              </a>
            </li>
          </ul>
        </nav>
        <section class="about-content">
          <h1>
            Comparti
            <i
              style="margin-left: 20px"
              class="nes-icon whatsapp is-medium nes-pointer"
              @click="shareOnWhatsApp"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon twitter is-medium nes-pointer"
              @click="shareOnTwitter"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon instagram is-medium nes-pointer"
              @click="copyToClipboard"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon facebook is-medium nes-pointer"
              @click="shareOnFacebook"
            ></i>
          </h1>
          <section>
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Que acción buscas"
              class="nes-input"
              style="
                width: 80%;
                font-weight: bolder;
                color: rgb(60, 179, 113);
                background-color: black;
              "
            />
            <table
              style="width: 90%"
              id="keywords"
              cellspacing="0"
              cellpadding="0"
              class="retro-table"
            >
              <thead>
                <tr>
                  <th @click="sortTable('ticker')">
                    Ticker
                    <span
                      v-if="currentSort === 'ticker'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('fecha')">
                    Fecha
                    <span
                      v-if="currentSort === 'fecha'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('liquidacion')">
                    Liquidacion
                    <span
                      v-if="currentSort === 'liquidacion'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('dias')">
                    Dias
                    <span
                      v-if="currentSort === 'dias'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('meses')">
                    Meses
                    <span
                      v-if="currentSort === 'meses'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('precio')">
                    Precio
                    <span
                      v-if="currentSort === 'precio'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('paga')">
                    Paga
                    <span
                      v-if="currentSort === 'paga'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th>Porcentaje</th>
                  <th @click="sortTable('tna')">
                    TNA
                    <span
                      v-if="currentSort === 'tna'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('tem')">
                    TEM
                    <span
                      v-if="currentSort === 'tem'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('tea')">
                    TEA
                    <span
                      v-if="currentSort === 'tea'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredRows" :key="index">
                  <td
                    class="lalign"
                    style="width: 20%; font-size: 22px; padding: 0px"
                  >
                    {{ row.ticker }}
                  </td>
                  <td>{{ row.fecha.toLocaleString(2) }}</td>
                  <td>{{ row.liquidacion.toLocaleString(2) }}</td>
                  <td>{{ row.dias.toLocaleString(2) }}</td>
                  <td>{{ row.meses.toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.precio).toLocaleString(4) }}</td>
                  <td>$ {{ parseFloat(row.paga).toLocaleString(4) }}</td>
                  <td>
                    {{
                      (
                        ((parseFloat(row.paga) - parseFloat(row.precio)) /
                          parseFloat(row.precio)) *
                        100
                      ).toLocaleString(4)
                    }}
                    %
                  </td>
                  <td :class="getCellClass(row.tna)">
                    {{ parseFloat(row.tna).toLocaleString(2) }} %
                  </td>
                  <td :class="getCellClass(row.tem)">
                    {{ parseFloat(row.tem).toLocaleString(2) }} %
                  </td>
                  <td :class="getCellClass(row.tea)">
                    {{ parseFloat(row.tea).toLocaleString(2) }} %
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </div>
    </div>
</section>
    <!-- MOBILE VERSION -->
  <section style="margin-top: 125px;margin-bottom: 25px;" v-else>
    <section>
      <div style="margin-left:25px;">
         <p>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas')">CENTRAL</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/merval')">MERVAL</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/bonos')">BONOS</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/cedears')">CEDEARS</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/tasas')">TASAS</a>
        </p>
      </div>
      <div><h4 style="margin-top: 0px;text-align: center;">Comparti</h4>
      <i style="margin-left:35px;" class="nes-icon whatsapp  is-medium nes-pointer" @click="shareOnWhatsApp"></i>
      <i style="margin-left:35px;" class="nes-icon twitter   is-medium nes-pointer" @click="shareOnTwitter"></i>
      <i style="margin-left:35px;" class="nes-icon instagram is-medium nes-pointer"   @click="copyToClipboard"></i>
      <i style="margin-left:35px;" class="nes-icon facebook  is-medium nes-pointer" @click="shareOnFacebook"></i>
      </div>
      <div style="text-align: center;" v-if="cableGap">
            <h2>cable Brecha</h2>
            <p><strong>Más alto cable:</strong> {{ cableGap.highestcableRow.nombre }} (cable: $ {{ parseFloat(cableGap.highestcableRow.cable).toLocaleString(2) }})</p>
            <p><strong>Más bajo cable:</strong> {{ cableGap.lowestcableRow.nombre }} (cable: $  {{ parseFloat(cableGap.lowestcableRow.cable).toLocaleString(2) }})</p>
            <p><strong>Gap:</strong> {{ parseFloat(cableGap.gap).toLocaleString(2) }}</p>
      </div>
            <input
              type="text"
              v-model="searchQuery"   
              placeholder="Que acción buscas"
              class="nes-input"
              style="margin-left: 60px;width:60%;font-weight: bolder;color:rgb(60, 179, 113);background-color: black;"
            />
            <table style="width: 90%;" id="keywords" cellspacing="0" cellpadding="0" class="retro-table">
              <thead>
                <tr>
                  <th @click="sortTable('ticker')">Ticker
                    <span v-if="currentSort === 'ticker'" :class="currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('fecha')">Fecha
                    <span v-if="currentSort === 'fecha'" :class="currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('precio')">Precio
                    <span v-if="currentSort === 'precio'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('tna')">TNA
                    <span v-if="currentSort === 'tna'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('tem')">TEM
                    <span v-if="currentSort === 'tem'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('tea')">TEA
                    <span v-if="currentSort === 'tea'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredRows" :key="index">
                  <td class="lalign" style="width: 20%;font-size:22px;padding:0px;">{{ row.ticker }}</td>
                  <td>{{ (row.fecha).toLocaleString(2) }}</td>
                  <td>{{ parseFloat(row.precio).toLocaleString(4) }}</td>
                  <td :class="getCellClass(row.tna)">{{ parseFloat(row.tna).toLocaleString(2) }}</td>
                  <td :class="getCellClass(row.tem)">{{ parseFloat(row.tem).toLocaleString(2) }}</td>
                  <td :class="getCellClass(row.tea)">{{ parseFloat(row.tea).toLocaleString(2) }}</td>
                </tr>
              </tbody>
            </table>
          </section>
  </section>
  </template>
  
  <script>
  import AppButton from "./AppButton.vue";
  import axios from "axios";
  import { BASE_URL } from "./config.js";
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    name: "AppFinanceLetters",
    components: { AppButton },
    setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      //console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      //console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
    data() {
      return {
        currentTemplate: 4, // Set to display template 3 (Bonds)
        currentSort: "",
        currentSortOrder: "asc",
        searchQuery: "", // The search query input by the user
        imageSrc: "", // Image source for Base64 image
        letters: [],
      };
    },
    computed: {
      sortedData() {
        return [...this.letters].sort((a, b) => {
          if (this.currentSort) {
            let modifier = this.currentSortOrder === "asc" ? 1 : -1;
            return typeof a[this.currentSort] === "string"
              ? a[this.currentSort].localeCompare(b[this.currentSort]) * modifier
              : (a[this.currentSort] - b[this.currentSort]) * modifier;
          }
          return 0;
        });
      },
      filteredRows() {
        const query = this.searchQuery.toLowerCase();
        return this.sortedData.filter((row) =>
          Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(query)
          )
        );
      },
    },
    mounted() {
      this.getData();
    },
    methods: {
      getDynamicUrl(path) {
        return `${window.location.origin}${path}`;
      },
      shareOnWhatsApp() {
        const currentUrl = window.location.href;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(whatsappUrl, "_blank");
      },
      shareOnTwitter() {
        const currentUrl = window.location.href;
        const tweetText = "Miren esto! de @radars_app";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, "_blank");
      },
      copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert("Link copiado! Ahora puedes compartirlo");
        });
      },
      shareOnFacebook() {
        const currentUrl = window.location.href;
        const facebookUrl = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
        window.open(facebookUrl, "_blank");
      },
      toggleTemplate(templateNumber) {
        this.currentTemplate = templateNumber;
      },
      getData() {
        const path = `${BASE_URL}/api/finanzas/tasas`;
        axios
          .get(path)
          .then((response) => {
            this.letters = JSON.parse(response.data.letters);
            console.log(this.letters);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      sortTable(column) {
        if (this.currentSort === column) {
          this.currentSortOrder =
            this.currentSortOrder === "asc" ? "desc" : "asc";
        } else {
          this.currentSort = column;
          this.currentSortOrder = "asc";
        }
      },
      getCellClass(value) {
        return value >= 0 ? "negative" : "positive";
      },
    },
  };
  </script>
  
  <style scoped>
  @import "../assets/home.css";
  @import "../assets/nes.min.css";
  @font-face {
    font-family: "3270";
    src: url("@/assets/fonts/3270.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  .main-content,
  .about-content {
    background: #fff;
    width: 100%;
    min-width: 80%;
    padding: 20px;
    display: block;
    padding-top: 50px;
    /* border: 1px solid black; */
    border-radius: 14px;
    text-align: center;
  }
  .main-content {
    display: block;
    margin-top: 5px;
    height: 60px;
    min-width: 60%;
  }
  .one,
  .two,
  .three {
    width: 33.3%;
  }
  .one {
    float: left;
  }
  .two {
    float: center;
  }
  .three {
    float: right;
  }
  .about-content {
    margin-top: 10px;
    line-height: 1.3;
  }
  .basket {
    background: #167ce2;
    color: white;
    border: 4 px double #f7c31a;
    display: block;
    padding: 5px;
    width: 100%;
    z-index: 100;
  }
  .basket:hover {
    animation: flicker 3s infinite alternate;
  }
  /* Animate neon flicker */
  @keyframes flicker {
    0%,
    19%,
    22%,
    62%,
    64%,
    70%,
    100% {
      opacity: 0.99;
      text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
        1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
        1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
        0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
    }
    20%,
    21%,
    63%,
    65%,
    69.9% {
      opacity: 0.8;
      text-shadow: none;
    }
  }
  /* Cards Section Display*/
  @import url("https://fonts.googleapis.com/css?family=Vollkorn");
  .card {
    width: 240px; /* previous 180px, */
    position: relative;
    background-color: #bdac5c;
    border: 6px solid #473438;
    border-radius: 2px;
    margin: 0 auto;
    font-family: "3270", sans-serif, hel vetica;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
    display: inline-block;
    flex-wrap: wrap; /* This allows wrapping if there are too many cards to fit in one row */
    justify-content: space-between; /* Adjust the space between cards */
    flex: 1 1 calc(33.33% - 20px); /* Makes each card take up approximately 1/3 of the row with some margin */
    margin: 10px;
    box-sizing: border-box; /* Ensures padding and borders are included in the width and height */
    background-size: cover;
    background-position: center center;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.75em 0.75em 0em rgba(0, 0, 0, 0.4);
  }
  /* blur-effect */
  .about-content:hover .card:not(:hover) {
    filter: blur(1px);
    opacity: 0.75;
  }
  .card::after {
    position: absolute;
    top: -6vw;
    left: -25%;
    width: 200%;
    height: 9vw;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.15) 10%,
      rgba(255, 255, 255, 0.6) 100%
    );
    transform: rotateZ(35deg);
    content: "";
    transition: 0.5s;
  }
  .card:hover {
    transform: perspective(90vw) rotateX(3deg) scale(1.15);
    box-shadow: 0 1em 1.5em 0.25em rgba(0, 0, 0, 0.35);
  }
  .card:hover .basket {
    animation: flicker 3s infinite alternate;
  }
  .card:hover::after {
    top: -1vw;
  }
  
  .cardName {
    width: 90%;
    max-width: 220px;
    overflow: hidden;
    font-size: 8px;
    text-transform: uppercase;
    margin: 7px auto 3px auto;
    padding: 4px;
    box-shadow: #fbfaf8 2px 2px 5px 0px;
    padding-top: 6px;
    border-radius: 5px;
    border: 1px outset rgba(112, 88, 52, 0.8);
    margin-bottom: 4px;
    color: #060505;
    text-overflow: ellipsis;
    color: black;
    font-family: "Vollkorn", courier;
    display: inside;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    animation: slide 20s linear infinite;
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(calc(-100% + var(--slider-width)));
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  .cardName img {
    float: right;
    width: 25px;
  }
  .cardName h1 {
    margin: 2px;
    display: inline;
    font-family: "karla", sans-serif;
  }
  .cardImage {
    text-align: center;
  }
  .cardImage img {
    max-width: 85%;
    height: 220px;
    width: 185px;
    border: 2px solid #635d4d;
    margin-bottom: 7px;
    margin: 5px auto;
  }
  .picture {
    margin: 3px auto;
    border: 1px solid black;
    border-radius: 50%;
  }
  .cardDetails {
    width: 85%;
    border: 3px double #9b2e37;
    margin: 0 auto 10px auto;
    padding: 6px;
    font-size: 10px;
    background: #edd4bb;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inside;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .cardDetails h2 {
    padding-bottom: 3px;
    font-size: 11px;
    margin: 0;
    font-family: "Vollkorn", sans-serif;
  }
  .cardDetails p {
    font-style: i talic;
    font-size: 18px;
    text-align: justify;
    margin: 0;
  }
  .cardDetails hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 25px 0 4px 0;
  }
  .shadow {
    -webkit-box-shadow: 6px 4px 5px -3px #735638;
    -moz-box-shadow: 6px 4px 5px -3px #735638;
    box-shadow: 6px 4px 5px -3px #735638;
  }
  /* Table */
  .retro-table {
    margin: 0 auto;
    border-collapse: collapse;
    width: 80%;
    min-width: 60%;
  }
  
  .retro-table th,
  .retro-table td {
    padding: 1px;
    border: 2px solid #000;
    text-align: center;
    /* background-color: #fff; */
  }
  
  .retro-table th {
    font-size: 16px;
    background-color: black;
    color: white;
  }
  .retro-table tr:hover,
  .retro-table td:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(60, 179, 113);
  }
  .retro-table td {
    font-size: 14px;
    word-wrap: normal;
  }
  
  .retro-table img {
    width: 80px;
  }
  .basket {
    background: #167ce2;
    color: white;
    border: 4 px double #f7c31a;
    display: block;
    padding: 5px 10px;
    width: 100%;
    z-index: 100;
  }
  
  /* SLIDE-SHOW */
  .mySlides {
    display: none;
  }
  .slideshow-container {
    padding-top: 10px;
    margin: auto;
    position: relative;
    z-index: 999;
  }
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  .lalign {
    text-align: left;
  }
  
  /* Arrow styling */
  .arrow {
    color: white;
    margin-left: 8px;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  
  .up {
    border-bottom: 5px solid white;
    content: "▲";
  }
  
  .down {
    border-top: 5px solid white;
    content: "▼";
  }
  
  .positive {
    background-color: rgb(60, 179, 113);
    color: white;
  }
  
  .negative {
    background-color: crimson;
    color: white;
  }
  
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100px;
  }
  </style>
