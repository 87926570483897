<template>
    <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
  <div>
    <div style="text-align: center" class="slideshow-container">
      <!-- Toggle buttons to switch between templates -->
      <button class="dot" @click="toggleTemplate(1)"></button>
      <button class="dot" @click="toggleTemplate(2)"></button>
      <button class="dot" @click="toggleTemplate(3)"></button>

      <!-- Template 1 -->
      <template v-if="currentTemplate === 1">
        <div style="font-size: 20px">1/3</div>
        <section class="main-content clearfix">
          <div class="one">
            <h1>Todos los supers.</h1>
            <h4>En un solo lugar, filtrado por precio</h4>
          </div>
          <div class="three">
            <h1>Elegi.</h1>
            <h4>La opción más barata</h4>
          </div>
          <div class="two">
            <h1>Compara</h1>
            <h4>Al saber todos los precios</h4>
          </div>
        </section>
        <section class="about-content">
          <div v-for="(item, index) in df" :key="index" class="card">
            <div class="cardName">
              <h1>{{ item.name }}</h1>
            </div>
            <div class="cardImage">
              <router-link :to="`/${item.id}`">
                <img :src="item.image" :alt="item.title" />
              </router-link>
            </div>
            <div class="cardDetails">
              <p style="font-size: 18px; color: darkblue; font-weight: bold">
                $
                {{
                  item.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </p>
              <p>{{ item.store }}</p>
              <hr />
              <AppButton class="nes-btn is-primary basket" :product="item" />
            </div>
          </div>
        </section>


      </template>

      <!-- Template 2 -->
      <template v-else-if="currentTemplate === 2">
        <div style="font-size: 20px">2/3</div>
        <section class="main-content clearfix">
          <div class="one">
            <h1>Canasta INDEC.</h1>
            <h4>Mira la diferencia en cada producto.</h4>
          </div>
          <div class="three">
            <h1>Compartila y Controla</h1>
            <h4>A un click</h4>
          </div>
          <div class="two">
            <h1>Crea una canasta buscando toda la lista a la vez.</h1>
            <h4>Elegi por precio, mínimo-máximo, por cantidad u ofertas.</h4>
          </div>
        </section>
        <section class="about-content">
          <table class="retro-table">
            <thead>
              <tr>
                <th>Búsqueda</th>
                <th>Cantidad</th>
                <th>Imagen</th>
                <th>Producto</th>
                <th>Precio</th>
                <th></th>
                <th></th>
                <th></th>
                <th>Brecha</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in excel" :key="item.id">
                <td>
                  <h1 style="font-size: 22px; padding: 0px">
                    {{ item.producto }}
                  </h1>
                </td>
                <td>{{ item.mes }}</td>
                <td>
                  <router-link :to="`/${item.id}`">
                    <img :src="item.image" :alt="item.title" />
                  </router-link>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  ${{
                    parseFloat(item.price).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </td>
                <td><img :src="item.expimage" :alt="item.title" /></td>
                <td>{{ item.expname }}</td>
                <td>
                  ${{
                    parseFloat(item.expprice).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  ${{
                    parseFloat(item.gap).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  <h1 style="font-size: 18px; padding-bottom: 15px">
                    ${{
                      parseFloat(item.price).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </h1>
                  <AppButton class="basket" :product="item" />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </template>

      <!-- Template 3 -->
      <template v-else-if="currentTemplate === 3">
        <div style="font-size: 20px">3/3</div>
        <section style="padding-bottom: 400px" class="main-content clearfix">
          <h1>
            Comparte
            <i
              class="nes-icon whatsapp is-medium nes-pointer"
              style="margin-left: 20px"
              @click="shareOnWhatsApp"
            ></i>
            <i
              class="nes-icon twitter is-medium nes-pointer"
              style="margin-left: 20px"
              @click="shareOnTwitter"
            ></i>
            <i
              class="nes-icon instagram is-medium nes-pointer"
              style="margin-left: 20px"
              @click="copyToClipboard"
            ></i>
            <i
              class="nes-icon facebook is-medium nes-pointer"
              style="margin-left: 20px"
              @click="shareOnFacebook"
            ></i>
          </h1>
          <div class="lists">
            <div
              class="nes-container with-title is-centered"
              style="padding: 30px; text-align: left"
            >
              <h2 class="title" style="font-size: 30px">¿Quiénes Somos?</h2>
              <p>
                ▲ Radar es un e-market. Una plataforma donde los agentes
                encuentran lo que quieren bajo criterio de su preferencia
                (precio u otro como volumen, calidad, categoría, etc). Pueden
                comprar, vender y negociar entre las partes.
              </p>
            </div>
            <div
              class="nes-container is-dark with-title is-centered"
              style="padding: 30px; text-align: left"
            >
              <h2 class="title" style="font-size: 30px">
                ¿Quierés sumarte a nuestro equipo?
              </h2>
              <p style="text-align: center">
                Mandanos un email a ==>
                <i
                  class="nes-icon gmail is-medium nes-pointer"
                  style="margin-left: 20px"
                  @click="sentToGmail"
                ></i>
              </p>
            </div>
            <div
              class="nes-container with-title is-centered"
              style="padding: 30px; text-align: left"
            >
              <h2 class="title" style="font-size: 30px">
                ¿Quierés invertir en RADAR?
              </h2>
              <p style="text-align: center">
                Evaluamos ofertas. Mandanos un email.
              </p>
            </div>
            <div
              class="nes-container is-dark with-title is-centered"
              style="padding: 30px; text-align: left"
            >
              <h2 class="title" style="font-size: 30px">Avances</h2>
              <p style="text-align: center">
                ▲ Permitir crear perfiles de tiendas para subir su catálogo.
              </p>
            </div>
          </div>
        </section>
      </template>
    </div>
  </div>
  </section>
    <!-- MOBILE  PART -->
  <section style="margin-top: 125px;" v-else>
    <div class="lists">
        <ul style="padding:30px; text-align: left;" class="nes-container with-title is-centered">
          <h1 style="font-size: 30px;"  class="title">¿Quienés Somos?</h1>
          <h1>▲ Radar es un e-market. Una plataforma donde encontras lo que querés bajo tu criterio (precio u otro como volumen, calidad, categoria, etc).
          <br>Podes comprar, vender y negociar entre las partes. Creaté tu cuenta en radar para vos y tu negocio.</h1>
        </ul>
    </div>
  </section>
</template>

<script>
import AppButton from "./AppButton.vue";
import axios from "axios";
import { BASE_URL } from "./config.js";
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: "AppBody",
  components: { AppButton },
  setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
  data() {
    return {
      currentTemplate: 1,
      templates: [1, 2, 3],
      excel: [],
      df: [],
      imageSrc: "",
    };
  },
  mounted() {
    this.getData();
    this.autoSwitch = setInterval(() => {
      this.nextTemplate();
    }, 12000);
  },
  beforeUnmount() {
    clearInterval(this.autoSwitch);
  },
  methods: {
    shareOnWhatsApp() {
      const currentUrl = window.location.href;
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
      )}`;
      window.open(whatsappUrl, "_blank");
    },
    shareOnTwitter() {
      const currentUrl = window.location.href;
      const tweetText = "Miren esto! de @radars_app";
      const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        currentUrl
      )}&text=${encodeURIComponent(tweetText)}`;
      window.open(twitterUrl, "_blank");
    },
    copyToClipboard() {
      const currentUrl = window.location.href;
      navigator.clipboard.writeText(currentUrl).then(() => {
        alert("Link copiado! Ahora puedes compartirlo");
      });
    },
    shareOnFacebook() {
      const currentUrl = window.location.href;
      const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
        currentUrl
      )}`;
      window.open(facebookUrl, "_blank");
    },
    toggleTemplate(templateNumber) {
      this.currentTemplate = templateNumber;
    },
    nextTemplate() {
      this.currentTemplate = (this.currentTemplate % this.templates.length) + 1;
    },
    getData() {
      const path = "/api/datahome";
      axios
        .get(path)
        .then((response) => {
          if (response.data.excel) {
            this.excel = JSON.parse(response.data.excel);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      const yerba =
        "/api/busqueda/papas fritas, yerba rosamonte 500, cunnington cola 1.5, queso cremon, limón, jamón, leche 1 litro, papel higienico";
      axios
        .get(yerba)
        .then((response) => {
          if (response.data.df) {
            this.df = JSON.parse(response.data.df);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      const images = `${BASE_URL}/api/images`;
      axios
        .get(images)
        .then((response) => {
          this.imageSrc = `data:image/png;base64,${response.data.image}`;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/home.css";
@import "../assets/nes.min.css";
@font-face {
  font-family: "3270";
  src: url("@/assets/fonts/3270.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
.main-content,
.about-content {
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 14px;
  text-align: center;
  z-index: 1000;
}
.main-content {
  margin-top: 5px;
  height: 30px;
}
.one,
.two,
.three {
  width: 33.3%;
}
.one {
  float: left;
}
.two {
  float: center;
}
.three {
  float: right;
}
.about-content {
  margin-top: 10px;
  line-height: 1.3;
}
.basket {
  background: #167ce2;
  color: white;
  border: 4 px double #f7c31a;
  display: block;
  padding: 5px;
  width: 100%;
  z-index: 100;
}
.basket:hover {
  animation: flicker 3s infinite alternate;
}
/* Animate neon flicker */
@keyframes flicker {
  0%,
  19%,
  22%,
  62%,
  64%,
  70%,
  100% {
    opacity: 0.99;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
      1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
      1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
      0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
  }
  20%,
  21%,
  63%,
  65%,
  69.9% {
    opacity: 0.8;
    text-shadow: none;
  }
}
/* Cards Section Display*/
@import url("https://fonts.googleapis.com/css?family=Vollkorn");
.card {
  width: 240px; /* previous 180px, */
  position: relative;
  background-color: #bdac5c;
  border: 6px solid #473438;
  border-radius: 2px;
  margin: 0 auto;
  font-family: "3270", sans-serif, helvetica;
  border-radius: 10px;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
  display: inline-block;
  flex-wrap: wrap; /* This allows wrapping if there are too many cards to fit in one row */
  justify-content: space-between; /* Adjust the space between cards */
  flex: 1 1 calc(33.33% - 20px); /* Makes each card take up approximately 1/3 of the row with some margin */
  margin: 10px;
  box-sizing: border-box; /* Ensures padding and borders are included in the width and height */
  background-size: cover;
  background-position: center center;
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0.75em 0.75em 0em rgba(0, 0, 0, 0.4);
}
/* blur-effect */
.about-content:hover .card:not(:hover) {
  filter: blur(1px);
  opacity: 0.75;
}
.card::after {
  position: absolute;
  top: -6vw;
  left: -25%;
  width: 200%;
  height: 9vw;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.15) 10%,
    rgba(255, 255, 255, 0.6) 100%
  );
  transform: rotateZ(35deg);
  content: " ";
  transition: 0.5s;
}
.card:hover {
  transform: perspective(90vw) rotateX(3deg) scale(1.15);
  box-shadow: 0 1em 1.5em 0.25em rgba(0, 0, 0, 0.35);
}
.card:hover .basket {
  animation: flicker 3s infinite alternate;
}
.card:hover::after {
  top: -1vw;
}

.cardName {
  width: 90%;
  max-width: 220px;
  margin: 7px auto 3px auto;
  padding: 4px;
  box-shadow: #fbfaf8 2px 2px 5px 0px;
  padding-top: 6px;
  border-radius: 5px;
  border: 1px outset rgba(112, 88, 52, 0.8);
  margin-bottom: 4px;
  text-overflow: ellipsis;
  color: black;
  font-family: "Vollkorn", courier;
  display: inside;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  animation: slide 20s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(calc(-100% + var(--slider-width)));
  }

  100% {
    transform: translateX(0%);
  }
}
.cardName img {
  float: right;
  width: 25px;
}
.cardName h1 {
  margin: 2px;
  display: inline;
  font-family: Arial, Helvetica, "karla", sans-serif;
  font-size: 14px;
}
.cardImage {
  text-align: center;
}
.cardImage img {
  max-width: 85%;
  height: 220px;
  width: 185px;
  border: 2px solid #635d4d;
  margin-bottom: 7px;
  margin: 5px auto;
}
.picture {
  margin: 3px auto;
  border: 1px solid black;
  border-radius: 50%;
}
.cardDetails {
  width: 85%;
  border: 3px double #9b2e37;
  margin: 0 auto 10px auto;
  padding: 6px;
  font-size: 10px;
  background: #edd4bb;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inside;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
.cardDetails h2 {
  padding-bottom: 3px;
  font-size: 11px;
  margin: 0;
  font-family: "Vollkorn", sans-serif;
}
.cardDetails p {
  font-style: i talic;
  font-size: 18px;
  text-align: justify;
  margin: 0;
}
.cardDetails hr {
  border: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 25px 0 4px 0;
}
.shadow {
  -webkit-box-shadow: 6px 4px 5px -3px #735638;
  -moz-box-shadow: 6px 4px 5px -3px #735638;
  box-shadow: 6px 4px 5px -3px #735638;
}
/* Table */
.retro-table {
  margin: 0 auto;
  border-collapse: collapse;
}

.retro-table th,
.retro-table td {
  padding: 1px;
  border: 2px solid #000;
  /* background-color: #fff; */
}

.retro-table th {
  font-size: 16px;
  background-color: black;
  color: white;
}
.retro-table tr:hover,
.retro-table td:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(60, 179, 113);
}
.retro-table td {
  font-size: 14px;
}

.retro-table img {
  width: 80px;
}
.basket {
  background: #167ce2;
  color: white;
  border: 4 px double #f7c31a;
  display: block;
  padding: 5px 10px;
  width: 100%;
  z-index: 100;
}

/* SLIDE-SHOW */
.mySlides {
  display: none;
}
.slideshow-container {
  position: relative;
  margin: auto;
  z-index: 999;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.dot {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.9s ease;
}
.dot:active,
.dot:hover {
  background-color: black;
}
</style>
