<template>
  <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
    <div
      v-for="(item, index) in excel.slice(0, 1)"
      :key="item.id"
      class="cardSide"
      id="app"
    >
      <div class="cardNameSide">
        <h1>{{ item.name }}</h1>
      </div>
      <div class="cardImageSide">
        <img :src="item.image" :alt="item.name" />
      </div>
      <div class="cardDetailsSide">
        <p>Tienda: {{ item.store }}</p>
        <p>Categoria: {{ item.category }}.</p>
        <br />
        <hr />
        <div class="rightAlignSide">
          PRECIO/
          <strong>
            $
            {{ parseFloat(item.price).toLocaleString(2) }}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </strong>
          BARATO/
          <strong
            >$
            <a :href="`/${item.cheapid}`">
              {{ parseFloat(item.cheaprice).toLocaleString(2) }}
            </a>
          </strong>
          &nbsp;&nbsp;&nbsp;&nbsp; CARO/
          <strong
            >$
            <a :href="`/${item.expenid}`">
              {{ parseFloat(item.expenprice).toLocaleString(2) }}</a
            ></strong
          >
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <br />
        <div><AppButton class="basket" :product="item" /></div>
      </div>
    </div>
    <section class="about-content">
      <h1 style="margin-top: 0px">
        Comparti
        <i
          style="margin-left: 20px"
          class="nes-icon whatsapp is-medium nes-pointer"
          @click="shareOnWhatsApp"
        ></i>
        <i
          style="margin-left: 20px"
          class="nes-icon twitter is-medium nes-pointer"
          @click="shareOnTwitter"
        ></i>
        <i
          style="margin-left: 20px"
          class="nes-icon instagram is-medium nes-pointer"
          @click="copyToClipboard"
        ></i>
        <i
          style="margin-left: 20px"
          class="nes-icon facebook is-medium nes-pointer"
          @click="shareOnFacebook"
        ></i>
      </h1>
      <h1>Productos Similares.</h1>
      <!-- Section about Cards-Product display -->
      <div v-for="(item, index) in df" :key="index" class="card">
        <!-- <button class="basket">COMPRA</button> -->
        <div class="cardName">
          <h1>{{ item.name }}</h1>
          <!-- <img src="https://vignette.wikia.nocookie.net/yugioh/images/a/a1/EARTH.svg/revision/latest/scale-to-width-down/300?cb=20120918053843" alt="Element"> -->
        </div>
        <div class="cardImage">
          <a :href="`/${item.id}`"><img :src="item.image" :alt="item.title" /></a>
          <!-- <img src="https://acdn.mitiendanube.com/stores/001/211/660/products/buhero-ok1-39d11ef5e3ab14c36c16237763226566-640-0.png" alt="yugioh card"> -->
        </div>
        <div class="cardDetails">
          <!-- DESCRIPCION -->
          <p style="font-size: 18px; color: darkblue; font-weight: bold">
            $ {{ parseFloat(item.price).toLocaleString(2) }}
          </p>
          <!-- CATEGORIA -->
          <p>{{ item.store }}</p>
          <hr />
          <AppButton class="basket" :product="item" />
        </div>
      </div>
    </section>
</section>
<!-- MOBILE VERSION -->
<section style="margin-top: 125px;margin-bottom: 125px;" v-else>
  <div v-for="(item, index) in excel.slice(0,1)" :key="item.id" class="card">
    <div class="cardName">
      <h1>{{ item.name }}</h1>
    </div>
    <div class="cardImage">
      <router-link :to="`/${item.id}`">
        <a :href="`/${item.id}`"><img :src="item.image" :alt="item.title" /></a>
      </router-link>
    </div>
    <div class="cardDetails">
  <p style="font-size:18px;color:darkblue;font-weight: bold;">
    $ {{ item.price.toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
  </p>
  <p>Tienda: {{ item.store }}</p>
  <div>
    <nav>
      <ul class="inline-list">
        <li>
          <p style="font-size:18px; display: inline;">rango </p>
          <a :href="`/${item.cheapid}`">
            <span style="font-size:18px;color:darkblue;">(${{ item.cheaprice.toLocaleString(undefined, { minimumFractionDigits: 0 }) }}</span>
          </a>
        </li>
        <li>
          <p style="display: inline;"></p>
          <a :href="`/${item.expenid}`">
            <span style="font-size:18px;color:darkblue;">${{ item.expenprice.toLocaleString(undefined, { minimumFractionDigits: 0 }) }})</span>
          </a>
        </li>
      </ul>
    </nav>
    <hr />
  </div>
  </div>
  <AppButton class="nes-btn is-primary basket" :product="item" />
  </div>
  <div>
    <div><h2 style="margin-top: 0px;text-align: center;">Comparti</h2>
            <i style="margin-left:20px;" class="nes-icon whatsapp  is-medium nes-pointer" @click="shareOnWhatsApp"></i>
            <i style="margin-left:20px;" class="nes-icon twitter   is-medium nes-pointer" @click="shareOnTwitter"></i>
            <i style="margin-left:20px;" class="nes-icon instagram is-medium nes-pointer"   @click="copyToClipboard"></i>
            <i style="margin-left:20px;" class="nes-icon facebook  is-medium nes-pointer" @click="shareOnFacebook"></i>
    </div>
    <h2>PRODUCTOS SIMILARES</h2>
    <div v-for="(item, index) in df" :key="index" class="product-card"> <!-- DISPLAY TABLE AS GRID CARDS -->
      <div class="product-image">
        <a :href="`/${item.id}`"><img :src="item.image" :alt="item.title" /></a>
      </div>
      <div class="product-details">
        <h3 class="product-title">{{ item.name }}</h3>
        <ul class="product-features">
          <li>de: {{ item.store }}.</li>
        </ul>
        <div class="product-actions">
          <span class="product-price">$ {{ parseFloat(item.price).toLocaleString(2) }}</span>
        </div>
        <AppButton class="basket" :product="item" />
      </div>
    </div>    
  </div>
</section>


</template>

<script>
import axios from 'axios';
import AppButton from './AppButton.vue';
import { ref, onMounted, onUnmounted } from 'vue';
import { BASE_URL } from "./config.js";

export default {
  name: 'AppProduct',
  components: {AppButton},
  setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      //console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      //console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
    data() {
      return {
        /*back-end excel points to merged.xlsx & df to radar.xlsx*/ excel: [],
        df: [],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      async getData() {
        const lookup = this.$route.params.id;
        const path = `${BASE_URL}/api/id/` + lookup;
        try {
          const response = await axios.get(path);
  
          this.excel = response.data.excel;
          this.excel = JSON.parse(this.excel);
          this.df = response.data.df;
          this.df = JSON.parse(this.df);
          console.log(this.excel);
          console.log("this is the same dataframe", this.df);
        } catch (error) {
          console.error("Error fetching data:", error, path);
        }
      },
      shareOnWhatsApp() {
        const currentUrl = window.location.href;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(whatsappUrl, "_blank");
      },
      shareOnTwitter() {
        const currentUrl = window.location.href;
        const tweetText = "Check this out!";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, "_blank");
      },
      copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert("Link copied! You can now paste it on Instagram.");
        });
      },
      shareOnFacebook() {
        const currentUrl = window.location.href;
        const facebookUrl = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
        window.open(facebookUrl, "_blank");
      },
    },
  };
  </script>
  
  <style scoped>
  .about-content {
    background: #fff;
    width: 100%;
    padding: 20px;
    /*border: 1px solid black;*/
    border-radius: 14px;
    text-align: center;
  }
  
  .cardSide {
    width: 850px;
    height: 350px;
    background-color: #bdac5c;
    border: 6px solid #473438;
    border-radius: 2px;
    margin: 0 auto;
    font-family: sans-serif, helvetica;
    z-index: 2;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    transition-duration: 2s;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
    margin-top: 100px;
  }
  
  .cardNameSide {
    width: 67%;
    float: right;
    font-size: 9px;
    text-transform: uppercase;
    margin: 7px auto 3px auto;
    box-shadow: #130f01 2px 2px 5px 0px;
    padding-top: 6px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: 1px outset rgba(112, 88, 52, 0.8);
    margin-bottom: 40px;
    color: #ffd4d4;
    text-shadow: #0072a4 -1px -1px 4px;
  }
  
  .cardNameSide img {
    float: left;
    width: 325px;
  }
  
  .cardNameSide h1 {
    margin-left: 10px;
    font-size: 17px;
    padding: 1%;
    display: inline-block;
    font-family: "Vollkorn", sans-serif;
  }
  
  .cardImageSide {
    text-align: center;
  }
  
  .cardImageSide img {
    width: 85%;
    height: 280px;
    margin: 10px;
    border: 5px solid #635d4d;
    margin-bottom: 20px;
    max-width: 85%;
    height: 320px;
    width: 245px;
    border: 2px solid #635d4d;
    margin-bottom: 7px;
    margin: 5px auto;
  }
  
  .cardDetailsSide {
    font-family: "Vollkorn", sans-serif;
    position: absolute;
    width: 65%;
    height: 230px;
    float: left;
    border: 3px double #9b2e37;
    margin: 0 auto auto;
    font-size: 20px;
    top: 25%;
    right: 1%;
    background: #edd4bb;
    overflow: hidden;
    padding-bottom: 6px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inside;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .cardDetailsSide h2 {
    padding-top: 3px;
    font-size: 25%;
    margin: 0;
    font-family: "Vollkorn", sans-serif;
  }
  
  .cardDetailsSide p {
    font-style: italic;
    font-size: 20px;
    text-align: justify;
    margin: 0;
  }
  
  .cardDetailsSide hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 25px 0 4px 0;
  }
  
  .rightAlign {
    text-align: right;
  }
  
  .shadow {
    -webkit-box-shadow: 6px 4px 5px -3px #735638;
    -moz-box-shadow: 6px 4px 5px -3px #735638;
    box-shadow: 6px 4px 5px -3px #735638;
  }
  
  .basket {
    background: #0eaf4c;
    color: white;
    border: 4 px double #f7c31a;
    display: block;
    padding: 5px;
    width: 100%;
    z-index: 100;
  }
  .basket:hover {
    animation: flicker 3s infinite alternate;
  }
  
  /* Animate neon flicker */
  @keyframes flicker {
    0%,
    19%,
    22%,
    62%,
    64%,
    70%,
    100% {
      opacity: 0.99;
      text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
        1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
        1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
        0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
    }
    20%,
    21%,
    63%,
    65%,
    69.9% {
      opacity: 0.8;
      text-shadow: none;
    }
  }
  
  .card:hover .basket {
    animation: flicker 3s infinite alternate;
  }
  
  .card {
    width: 240px; /* previous 180px, */
    position: relative;
    background-color: #bdac5c;
    border: 6px solid #473438;
    border-radius: 2px;
    margin: 0 auto;
    font-family: "3270", sans-serif, hel vetica;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
    display: inline-block;
    flex-wrap: wrap; /* This allows wrapping if there are too many cards to fit in one row */
    justify-content: space-between; /* Adjust the space between cards */
    flex: 1 1 calc(33.33% - 20px); /* Makes each card take up approximately 1/3 of the row with some margin */
    margin: 10px;
    box-sizing: border-box; /* Ensures padding and borders are included in the width and height */
    background-size: cover;
    background-position: center center;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0.75em 0.75em 0em rgba(0, 0, 0, 0.4);
  }
  /* blur-effect */
  .about-content:hover .card:not(:hover) {
    filter: blur(1px);
    opacity: 0.75;
  }
  .card::after {
    position: absolute;
    top: -6vw;
    left: -25%;
    width: 200%;
    height: 9vw;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.15) 10%,
      rgba(255, 255, 255, 0.6) 100%
    );
    transform: rotateZ(35deg);
    content: "";
    transition: 0.5s;
  }
  .card:hover {
    transform: perspective(90vw) rotateX(3deg) scale(1.15);
    box-shadow: 0 1em 1.5em 0.25em rgba(0, 0, 0, 0.35);
  }
  .card:hover .basket {
    animation: flicker 3s infinite alternate;
  }
  .card:hover::after {
    top: -1vw;
  }
  
  .cardName {
    width: 90%;
    max-width: 220px;
    overflow: hidden;
    font-size: 8px;
    text-transform: uppercase;
    margin: 7px auto 3px auto;
    padding: 4px;
    box-shadow: #fbfaf8 2px 2px 5px 0px;
    padding-top: 6px;
    border-radius: 5px;
    border: 1px outset rgba(112, 88, 52, 0.8);
    margin-bottom: 4px;
    color: white;
    font-family: "Vollkorn", sans-serif;
    text-shadow: black -1px -1px 4px;
    text-overflow: ellipsis;
    display: inside;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .cardName img {
    float: right;
    width: 25px;
  }
  .cardName h1 {
    margin: 2px;
    display: inline;
    font-family: "karla", sans-serif;
  }
  .cardImage {
    text-align: center;
  }
  .cardImage img {
    max-width: 85%;
    height: 220px;
    width: 185px;
    border: 2px solid #635d4d;
    margin-bottom: 7px;
    margin: 5px auto;
  }
  .picture {
    margin: 3px auto;
    border: 1px solid black;
    border-radius: 50%;
  }
  .cardDetails {
    width: 85%;
    border: 3px double #9b2e37;
    margin: 0 auto 10px auto;
    padding: 6px;
    font-size: 10px;
    background: #edd4bb;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inside;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .cardDetails h2 {
    padding-bottom: 3px;
    font-size: 11px;
    margin: 0;
    font-family: "Vollkorn", sans-serif;
  }
  .cardDetails p {
    font-style: i talic;
    font-size: 18px;
    text-align: justify;
    margin: 0;
  }
  .cardDetails hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 25px 0 4px 0;
  }
  .shadow {
    -webkit-box-shadow: 6px 4px 5px -3px #735638;
    -moz-box-shadow: 6px 4px 5px -3px #735638;
    box-shadow: 6px 4px 5px -3px #735638;
  }
  /* Responsive */
@media (max-width: 768px) {
  .card:hover .basket { 
    animation: flicker 3s infinite alternate;    
  }
  
  .card {
    width: 98%; /* previous 180px, */
    height: 520px;
    position: relative;
    background-color: #bdac5c;
    border: 6px solid #473438;
    border-radius: 2px;
    margin: 0 auto;
    font-family: '3270',sans-serif, hel  vetica;
    border-radius: 10px;
    box-shadow: 0 5px 35px rgba(0,0,0,.65);
    display: inline-block;
    flex-wrap: wrap; /* This allows wrapping if there are too many cards to fit in one row */
    justify-content: space-between; /* Adjust the space between cards */
    flex: 1 1 calc(33.33% - 20px); /* Makes each card take up approximately 1/3 of the row with some margin */
    margin: 10px;
    box-sizing: border-box; /* Ensures padding and borders are included in the width and height */
    background-size: cover;
    background-position: center center;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 .75em .75em 0em rgba(0, 0, 0, 0.4);
    margin-left: 5px;
    margin-right: 25px;
  }
  /* blur-effect */
  .about-content:hover .card:not(:hover)  {
    filter: blur(1px);
    opacity: 0.75;
  }
  .card::after{
    position: absolute;
    top: -6vw;
    left: -25%;
    width: 200%;
    height: 9vw;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.15) 10%, rgba(255, 255, 255, 0.6) 100%);
    transform: rotateZ(35deg);
    content: '';
    transition: 0.5s; 
}
.card:hover{
  transform: perspective(90vw) rotateX(3deg) scale(1.15);
  box-shadow: 0 1em 1.5em 0.25em rgba(0, 0, 0, 0.35);
} 
.card:hover .basket { 
  animation: flicker 3s infinite alternate;    
}
.card:hover::after{
  top: -1vw;
}

.cardName {
  width: 90%;
  max-width: 240px;
  overflow: hidden;
  font-size: 8px;
  text-transform: uppercase;
  margin: 7px auto 3px auto;
  padding: 4px;
  box-shadow: #fbfaf8 2px 2px 5px 0px;
  padding-top: 6px;
  border-radius: 5px;
  border: 1px outset rgba(112, 88, 52, 0.8);
  margin-bottom: 4px;
  color: white;
  font-family: "Vollkorn", sans-serif;
  text-shadow: black -1px -1px 4px; 
  text-overflow: ellipsis;
  display: inside;  
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.cardName img {
  float: right;
  width: 25px;
}
.cardName h1 {
  font-size: .9rem; /* Adjust font size for price */
  margin: 2px;
  display: inline;
  font-family: "karla", sans-serif;
}
.cardImage {
  text-align: center;
}
.cardImage img {
  max-width: 85%;
  height: 270px;
  width: 245px;
  border: 2px solid #635d4d;
  margin-bottom: 7px;
  margin: 5px auto;  
}
.picture {
  margin: 3px auto;
  border: 1px solid black;
  border-radius: 50%;
}
.cardDetails {
  width: 75%;
  border: 3px double #9b2e37;
  margin: 0 auto 10px auto;
  padding: 6px;
  font-size: 10px;
  background: #edd4bb;
  max-width: 300px;
  overflow: hidden;
  text-align: center;
  padding-bottom: 14px;
  font-style: italic;
  font-size: 18px;
}

.inline-list {
  display: flex; /* Arrange list items horizontally */
  justify-content: center; /* Add space between items */
  align-items: center;
  list-style: none; /* Remove bullets */
  padding: 0;
  margin: 0;
}

.inline-list li {
  margin: 0 10px;
  font-size: 22px;
  white-space: nowrap; /* Prevent wrapping */
}

.inline-list a {
  text-decoration: none;
  color: darkblue;
}

.inline-list a:hover {
  text-decoration: underline;
  color: blue;
}
.cardDetails hr {
  border: 0;
  height: 2px;
  margin: 25px 0 4px 0;
  background: #edd4bb;
}
/* Container for the product card */
.product-card {
  display: flex !important;
  align-items: flex-start !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Image section */
.product-image {
  flex: 1; /* Take 1 part of the flex container */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px; /* Set a fixed max width for the image */
}

.product-image img {
  width: 100%; /* Ensure image scales properly */
  height: auto;
  border-radius: 5px;
}

/* Details section */
.product-details {
  flex: 2; /* Take 2 parts of the flex container */
  padding-left: 15px;
}

/* Details section */
.product-details {
  flex: 2;
  padding-left: 15px;
}

.product-title {
  font-size: 1rem; /* Shrink title font size */
  font-weight: bold;
  margin-bottom: 5px;
}

.product-description {
  font-size: 0.8rem; /* Reduce font size for description */
  color: #666;
  margin-bottom: 10px;
}

.product-features {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 10px;
  color: #333;
  font-size: 0.8rem; /* Shrink font size for features */
}

/* Actions (buttons and price) */
.product-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.product-actions .btn {
  padding: 6px 10px; /* Shrink button size */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.8rem; /* Reduce font size for buttons */
}

.product-actions .btn:hover {
  background-color: #f0f0f0;
}

.product-actions .product-price {
  font-size: 1.2rem; /* Adjust font size for price */
  font-weight: bold;
  margin-left: auto;
}

}
  </style>
