import { createRouter, createWebHistory } from "vue-router";

// Import views and components
import HomeView from "@/views/HomeView.vue";
import CartView from "@/views/CartView.vue";
import ContactView from "@/views/ContactView.vue";
import Product from "@/views/Product.vue";
import Basket from "@/views/Basket.vue";
import Search from "@/views/Search.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import AppFinanceCentral from "@/components/AppFinanceCentral.vue";
import AppFinanceMerval from "@/components/AppFinanceMerval.vue";
import AppFinanceBonds from "@/components/AppFinanceBonds.vue";
import AppFinanceLetters from "@/components/AppFinanceLetters.vue";
import AppFinanceCedears from "@/components/AppFinanceCedears.vue";

const routes = [
  {
    path: "/", // Root path
    name: "Public",
    component: DefaultLayout, // Main layout
    redirect: "/",
    children: [
      {
        path: "/",
        name: "Home",
        component: HomeView,
      },
      {
        path: "/contacto",
        name: "Contact",
        component: ContactView,
      },
      {
        path: "/finanzas",
        name: "Finance",
        component: AppFinanceCentral,
      },
      {
        path: "/finanzas/merval",
        name: "AppFinanceMerval",
        component: AppFinanceMerval,
      },
      {
        path: "/finanzas/bonos",
        name: "AppFinanceBonds",
        component: AppFinanceBonds,
      },
      {
        path: "/finanzas/tasas",
        name: "AppFinanceLetters",
        component: AppFinanceLetters,
      },
      {
        path: "/finanzas/cedears",
        name: "AppFinanceCedears",
        component: AppFinanceCedears,
      },
      {
        path: "/canasta",
        name: "Basket",
        component: Basket,
      },
      {
        path: "/carrito",
        name: "Cart",
        component: CartView,
      },
      {
        path: "/:id",
        name: "Product",
        component: Product,
        props: true, // Pass route params as props to the component
      },
      {
        path: "/busqueda/:searchQuery",
        name: "Search",
        component: Search,
        props: true, // Pass route params as props to the component
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(), // Use HTML5 history mode
  routes,
});

export default router;
