<template>
  <footer>
    <p>REDES &copy; Radar</p>
    <!-- <i style="margin-left:20px;" class="nes-icon instagram is-medium nes-pointer" @click="copyToClipboard"></i> -->
    <a
      href="https://x.com/radars_app"
      target="_blank"
      style="margin-left: 20px"
      class="nes-icon twitter is-medium nes-pointer"
    ></a>
    <!--<a :href="getDynamicUrl('/finanzas/MERVAL')" target="_blank" style="margin-left:20px;" class="nes-icon instagram   is-medium nes-pointer"></a>-->
    <a
      href="https://www.facebook.com/profile.php?id=61567398525283"
      target="_blank"
      style="margin-left: 20px"
      class="nes-icon facebook is-medium nes-pointer"
    ></a>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
/*@import '../assets/home.css';*/

footer {
  bottom: 0px;
  background: rgb(237, 237, 237);
  text-align: center;
  margin-top: 5%;
  padding: 30px;
}
</style>
