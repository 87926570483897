<template>
  <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
    <div>
      <div style="text-align: center" class="slideshow-container">
        <!-- Toggle button to switch to CENTRAL template -->
        <nav class="subheader">
          <ul class="nes-list">
            <li>
              <a
                :href="getDynamicUrl('/finanzas')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                CENTRAL
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/MERVAL')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                MERVAL
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/BONOS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                BONOS
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/CEDEARS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                CEDEARS
              </a>
            </li>
            <li>
              <a
                :href="getDynamicUrl('/finanzas/TASAS')"
                target="_self"
                style="
                  width: 100%;
                  margin: auto;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-weight: bold;
                "
                class="nes-btn is-primary"
              >
                TASAS
              </a>
            </li>
          </ul>
        </nav>
  
        <!-- Display Template 1 content directly -->
        <section class="main-content clearfix">
          <h1>
            Comparti
            <i
              style="margin-left: 20px"
              class="nes-icon whatsapp is-medium nes-pointer"
              @click="shareOnWhatsApp"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon twitter is-medium nes-pointer"
              @click="shareOnTwitter"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon instagram is-medium nes-pointer"
              @click="copyToClipboard"
            ></i>
            <i
              style="margin-left: 20px"
              class="nes-icon facebook is-medium nes-pointer"
              @click="shareOnFacebook"
            ></i>
          </h1>
          <section>
            <img
              style="width: 80%"
              :src="imageSrc"
              alt="Image from Flask"
              v-if="imageSrc"
            />
            <div class="lists">
              <ul
                style="text-align: left"
                class="nes-container is-dark with-title is-centered"
              >
                <h1 class="title">TIPOS DE CAMBIO</h1>
                <li>Solidario: Oficial X 1.3 X 1.35.</li>
                <li>
                  Dolarizar: (M2 + billete_publico + billete_privado +
                  total_depositos_privado + leliqs) / stock_reservas.
                </li>
                <li>FX Fundamental: Pasivo / Stock Reservas.</li>
                <li>
                  Monetarista: Solidario X (total_base X retorno cable) <br />
                  {Busca reflejar el blue al internalizar sus cambios en la Base
                  Monetario}
                </li>
                <li>Cable: Ratio Conversión Coca Cola.</li>
              </ul>
            </div>
            <table
              style="width: 90%"
              id="keywords"
              cellspacing="0"
              cellpadding="0"
              class="retro-table"
            >
              <thead>
                <tr>
                  <th @click="sortTable('fecha')">
                    Fecha
                    <span
                      v-if="currentSort === 'fecha'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('oficial')">
                    Oficial
                    <span
                      v-if="currentSort === 'oficial'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('solidario')">
                    Solidario
                    <span
                      v-if="currentSort === 'solidario'"
                      :class="
                        currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('dolarizar')">
                    Dolarizar
                    <span
                      v-if="currentSort === 'dolarizar'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('cable')">
                    CableKO
                    <span
                      v-if="currentSort === 'cable'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('fxfundamental')">
                    FXFundamental
                    <span
                      v-if="currentSort === 'fxfundamental'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                  <th @click="sortTable('monetarista')">
                    Monetarista
                    <span
                      v-if="currentSort === 'monetarista'"
                      :class="
                        currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'
                      "
                    ></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredRows" :key="index">
                  <td class="lalign">
                    <h1 style="font-size: 22px; padding: 0px">
                      {{
                        new Date(row.fecha).toLocaleDateString("es-AR", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                      }}
                    </h1>
                  </td>
                  <td>$ {{ parseFloat(row.oficial).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.solidario).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.dolarizar).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.cable).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.fxfundamental).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.monetarista).toLocaleString(2) }}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      </div>
    </div>
  </section>
      <!-- MOBILE VERSION -->
  <section style="margin-top: 125px;margin-bottom: 25px;" v-else>
    <div style="margin-left:25px;">
        <p>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas')">CENTRAL</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/merval')">MERVAL</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/bonos')">BONOS</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/cedears')">CEDEARS</a>
          <a style="padding: 0;" class="nes-btn is-warning"  :href="getDynamicUrl('/finanzas/tasas')">TASAS</a>
        </p>
    </div>
    <div><h4 style="margin-top: 0px;text-align: center;">Comparti</h4>
      <i style="margin-left:35px;" class="nes-icon whatsapp  is-medium nes-pointer" @click="shareOnWhatsApp"></i>
      <i style="margin-left:35px;" class="nes-icon twitter   is-medium nes-pointer" @click="shareOnTwitter"></i>
      <i style="margin-left:35px;" class="nes-icon instagram is-medium nes-pointer"   @click="copyToClipboard"></i>
      <i style="margin-left:35px;" class="nes-icon facebook  is-medium nes-pointer" @click="shareOnFacebook"></i>
    </div>
    <img style="width:90%;margin-left" :src="imageSrc" alt="Image from Flask" v-if="imageSrc"/>
    <table style="width:90%;" id="keywords" cellspacing="0" cellpadding="0" class="retro-table">
              <thead>
                <tr>
                  <th @click="sortTable('fecha')">Fecha
                    <span v-if="currentSort === 'fecha'" :class="currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('oficial')">Oficial
                    <span v-if="currentSort === 'oficial'" :class="currentSortOrder === 'asc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('dolarizar')">Dolarizar
                    <span v-if="currentSort === 'dolarizar'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                  <th @click="sortTable('cable')">CableKO
                    <span v-if="currentSort === 'cable'" :class="currentSortOrder === 'desc' ? 'arrow up' : 'arrow down'"></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in filteredRows" :key="index">
                  <td class="lalign"><h1 style="font: size 12px;padding:0px;">{{ new Date(row.fecha).toLocaleDateString("es-AR", { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</h1></td>
                  <td>$ {{ parseFloat(row.oficial).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.dolarizar).toLocaleString(2) }}</td>
                  <td>$ {{ parseFloat(row.cable).toLocaleString(2) }}</td>
                </tr>
              </tbody>
      </table>
  </section>
  </template>
  
  <script>
  import AppButton from "./AppButton.vue";
  import axios from "axios";
  import { ref, onMounted, onUnmounted } from 'vue';
  import { BASE_URL } from "./config"; // Import the centralized base URL
  
  export default {
    name: "AppFinanceCentral",
    components: { AppButton },
    setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      //console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      //console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
    data() {
      return {
        currentTemplate: 1, // Set to display template 1
        cambio: [], // Data for Template 1
        currentSort: "",
        currentSortOrder: "asc",
        searchQuery: "", // The search query input by the user
        imageSrc: "", // Image source for Base64 image
      };
    },
    computed: {
      sortedData() {
        return [...this.cambio].sort((a, b) => {
          if (this.currentSort) {
            let modifier = this.currentSortOrder === "asc" ? 1 : -1;
            return typeof a[this.currentSort] === "string"
              ? a[this.currentSort].localeCompare(b[this.currentSort]) * modifier
              : (a[this.currentSort] - b[this.currentSort]) * modifier;
          }
          return 0;
        });
      },
      filteredRows() {
        const query = this.searchQuery.toLowerCase();
        return this.sortedData.filter((row) =>
          Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(query)
          )
        );
      },
    },
    mounted() {
      this.getData();
    },
    methods: {
      getDynamicUrl(path) {
        return `${window.location.origin}${path}`;
      },
      shareOnWhatsApp() {
        const currentUrl = window.location.href;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(whatsappUrl, "_blank");
      },
      shareOnTwitter() {
        const currentUrl = window.location.href;
        const tweetText = "Miren esto! de @radars_app";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, "_blank");
      },
      copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert("Link copiado! Ahora puedes compartirlo");
        });
      },
      shareOnFacebook() {
        const currentUrl = window.location.href;
        const facebookUrl = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
        window.open(facebookUrl, "_blank");
      },
      toggleTemplate(templateNumber) {
        this.currentTemplate = templateNumber;
      },
      getData() {
        const path = `${BASE_URL}/api/finanzas`;
        axios
          .get(path)
          .then((response) => {
            this.cambio = JSON.parse(response.data.cambio);
            this.imageSrc = `data:image/png;base64,${response.data.image}`;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      sortTable(column) {
        if (this.currentSort === column) {
          this.currentSortOrder =
            this.currentSortOrder === "asc" ? "desc" : "asc";
        } else {
          this.currentSort = column;
          this.currentSortOrder = "asc";
        }
      },
      getCellClass(value) {
        return value >= 0 ? "positive" : "negative";
      },
    },
  };
  </script>
  
  <style scoped>
  @import "../assets/home.css";
  @import "../assets/nes.min.css";
  @font-face {
    font-family: "3270";
    src: url("@/assets/fonts/3270.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  .main-content,
  .about-content {
    background: #fff;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    padding-top: 70px;
    border-radius: 14px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .lists {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 600px;
  }
  
  .one,
  .two,
  .three {
    flex: 1;
    text-align: center;
    position: relative;
    margin: 10px 0;
  }
  
  .basket {
    background: #167ce2;
    color: white;
    display: block;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid #f7c31a;
    transition: all 0.3s;
  }
  
  .basket:hover {
    animation: flicker 3s infinite alternate;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 240px;
    background-color: #bdac5c;
    border: 6px solid #473438;
    border-radius: 10px;
    margin: 10px;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.65);
    position: relative;
    overflow: hidden;
    transition: 0.5s;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 1em 1.5em 0.25em rgba(0, 0, 0, 0.35);
  }
  
  .cardImage img {
    max-width: 100%;
    height: auto;
    border: 2px solid #635d4d;
    border-radius: 8px;
  }
  
  .cardName,
  .cardDetails {
    padding: 10px;
    text-align: center;
  }
  
  .cardDetails {
    background: #edd4bb;
    font-size: 12px;
    border-radius: 5px;
  }
  
  /* Flex container for table and lists */
  .retro-table,
  .lists {
    display: inline-flexbox;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }
  
  .retro-table {
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .retro-table th,
  .retro-table td {
    border: 2px solid #000;
    padding: 8px;
  }
  .retro-table th {
    font-size: 16px;
    background-color: black;
    color: white;
  }
  .retro-table tr:hover,
  .retro-table td:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(60, 179, 113);
  }
  .retro-table td {
    font-size: 14px;
    margin: auto;
    white-space: nowrap;
  }
  
  .subheader {
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100px;
    z-index: 1000;
  }
  
@media (max-width: 768px) {
  .retro-table {
    margin: 0 auto;
    border-collapse: collapse;
    width: 90%;
    min-width: 60%;

  }
  
  .retro-table th,
  .retro-table td {
    padding: 1px;
    border: 2px solid #000;
    text-align: center;
    /* background-color: #fff; */
  }
  
  .retro-table th {
    font-size: 16px;
    background-color:black;
    color:white;
  }
  .retro-table tr:hover,
  .retro-table td:hover {
    background-color:rgb(0,0,0);
    color:rgb(60, 179, 113); 
  }    
  .retro-table td {
    font-size: 9px;
    white-space: wrap;
  }
  
  .retro-table img {
    width: 80px;
  }

.inline-list {
  display: flex; /* Arrange list items horizontally */
  justify-content: center; /* Add space between items */
  align-items: center;
  list-style: none; /* Remove bullets */
  padding: 0;
  margin: 0;
}

.inline-list li {
  margin: 0 10px;
  font-size: 22px;
  white-space: nowrap; /* Prevent wrapping */
}

.inline-list a {
  text-decoration: none;
  color: darkblue;
}

.inline-list a:hover {
  text-decoration: underline;
  color: blue;
}
.cardDetails hr {
  border: 0;
  height: 2px;
  margin: 25px 0 4px 0;
  background: #edd4bb;
}
}
  </style>
