<template>
    <div v-if="product" class="plus-minus">
        <button :class="['custom-btn', loading ? 'disabled' : '']" @click="addOrRemove(-1)" type="button">-</button>
        <input type="number" v-model="qty" disabled class="qty-input" />
        <button :class="['custom-btn', loading ? 'disabled' : '']" @click="addOrRemove(1)" type="button">+</button>
    </div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    name: 'AppCartAddRemove',
    props: ['product'],
    data() {
        return {
            qty: 1,
            loading: false,
        };
    },
    methods: {
        async addOrRemove(number) {
            this.loading = true;
            if (number === 1) {
                if (this.qty < 1000) { // Cambiar a futuro acorde al stock el limite de compra
                    this.qty++;
                    this.product.qty = this.qty;
                    await this.$store.commit('updateCart', { product: this.product });
                    toast.success('AGREGADO AL CARRITO', {
                        autoClose: 1000,
                    });
                } else {
                    toast.warning('LIMITE', {
                        autoClose: 3000,
                    });
                }
            }
            if (number === -1) {
                if (this.qty > 1) {
                    this.qty--;
                    this.product.qty = this.qty;
                    await this.$store.commit('updateCart', { product: this.product });
                    toast.success('QUITADO', {
                        autoClose: 1000,
                    });
                } else {
                    toast.warning('LIMITE', {
                        autoClose: 3000,
                    });
                }
            }
            this.loading = false;
        },
    },
    mounted() {
        this.qty = this.product.qty;
    },
};
</script>

<style scoped>
.plus-minus {
    display: flex;
    align-items: center;
}

.custom-btn {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.custom-btn.disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.custom-btn:hover {
    background-color: #0056b3;
}

.qty-input {
    width: 100%;
    text-align: center;
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}
</style>

