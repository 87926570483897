<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AppContainer",
};
</script>

<style scoped>
@import "../assets/home.css";

.container {
  width: 80%;
  margin: 0 auto;
}
</style>
