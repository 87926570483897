<template>
  <section v-if="width > 786">
  <div id="app">
    <section class="cart-section">
      <div class="container">
        <div class="cart-wrapper">
          <div class="card">
            <div class="card-body">
    
              <div class="cart-content">
                <div class="cart-left">
                  <h1 style="text-align:left;"><router-link :to="{name:'Home'}" class="text-body">
                    <i class="fas fa-long-arrow-alt-left"></i>¿Te olvidaste algo? Volve a buscar
                  </router-link></h1>
                  <hr>
    
                  <div class="cart-header">
                    <p>Tienes {{ $store.state.cart.length }} productos en tu carrito.</p>
                  </div>
    
                  <!-- <div v-for="item in $store.state.cart" class="cart-item" :key="item.id">
                    <div class="item-details">
                      <div class="item-info">
                        <img :src="item.image" class="item-image" alt="Shopping item">
                        <p>{{ item.name }}</p>
                      </div>
                      <div class="item-quantity">
                        <AppCartAddRemove :product="item"/>
                      </div>
                      <div class="item-price">
                        <h5><i class="bi bi-currency-dollar"></i>{{ item.price * item.qty }}</h5>
                        <small v-if="item.hasDiscount" class="discount-price">
                          <i class="bi bi-currency-dollar"></i>{{ item.price }}
                        </small>
                        <a role="button" @click="removeItem(item)" class="remove-item-btn">
                          <i class="bi bi-trash3">ELIMINAR</i>
                        </a>
                      </div>
                    </div>
                  </div> -->
                  <section>
                    <table class="retro-table">
                      <thead>
                        <tr >
                          <th></th>
                          <th>Producto</th>
                          <th>Precio</th>
                          <th>Cantidad</th>
                        </tr>
                      </thead>
                      <tbody v-for="item in $store.state.cart" :key="item.id">
                        <tr>
                          <td><img :src="item.image" :alt="item.title" /> </td>
                          <td><h1 style="font-size:18px;padding-bottom:5px;">{{ item.name }}</h1></td>
                          <td><h1 style="font-size:18px;padding-bottom:5px;">${{ item.price.toLocaleString(2) }}</h1></td>
                          <td><h1 style="font-size:18px;padding-bottom:5px;text-align:center;"></h1> <AppButton class="basket" :product="item" /></td>
                          </tr>
                        </tbody>
                    </table>
                  </section>
    
                </div>
    
                <div class="cart-right">
                  <div class="cart-summary">
                    <div class="summary-header">
                      <h5>Descripción Carrito</h5>
                      <i class="bi bi-cart3"></i>
                    </div>
                    <hr>
                    <div class="summary-details">
                      <p>Subtotal</p>
                      <p><i class="bi bi-currency-dollar"></i>$ {{ $store.state.cartTotal.toLocaleString(2) }}</p>
                    </div>
                    <div class="summary-total">
                      <p>Total</p>
                      <p><i class="bi bi-currency-dollar"></i>$ {{ $store.state.cartTotal.toLocaleString(2) }}</p>
                    </div>
    
                    <button type="button" class="checkout-btn">COMPARTIR SELECCIÓN</button>
                  </div>
                </div>
    
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
<!-- MOBILE VERSION -->
<section style="margin-top: 125px;margin-bottom: 125px;" v-else>
  <section class="cart-section">
    <div class="container">
      <div class="cart-wrapper">
        <div class="card">
          <div class="card-body">
  
            <div class="cart-content">
              <div class="cart-left">
                <h1 style="text-align:left;"><router-link :to="{name:'Home'}" class="text-body">
                  <i class="fas fa-long-arrow-alt-left"></i>¿Te olvidaste algo? Volve a buscar
                </router-link></h1>
                <hr>
  
                <div class="cart-header">
                  <p>Tienes {{ $store.state.cart.length }} productos en tu carrito.</p>
                </div>
               <div class="cart-right">
                <div class="cart-summary">
                  <div class="summary-header">
                    <h5>Descripción Carrito</h5>
                    <i class="bi bi-cart3"></i>
                  </div>
                  <hr>
                  <div class="summary-details">
                    <p>Subtotal</p>
                    <p><i class="bi bi-currency-dollar"></i>$ {{ $store.state.cartTotal.toLocaleString(2) }}</p>
                  </div>
                  <div class="summary-total">
                    <p>Total</p>
                    <p><i class="bi bi-currency-dollar"></i>$ {{ $store.state.cartTotal.toLocaleString(2) }}</p>
                  </div>
  
                  <button type="button" class="checkout-btn">COMPARTIR SELECCIÓN</button>
                </div>
              </div>
  
            </div> 
                <section>
                  <table class="retro-table">
                    <thead>
                      <tr >
                        <th>Producto</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                      </tr>
                    </thead>
                    <tbody v-for="item in $store.state.cart" :key="item.id">
                      <tr>
                        <td><h1 style="font-size:18px;padding-bottom:5px;">{{ item.name }}</h1></td>
                        <td><h1 style="font-size:18px;padding-bottom:5px;">${{ item.price.toLocaleString(2) }}</h1></td>
                        <td><h1 style="font-size:18px;padding-bottom:5px;text-align:center;"></h1> <AppButton class="basket" :product="item" /></td>
                        </tr>
                      </tbody>
                  </table>
                </section>
  
              </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </section>
</section>


</template>
    
<script>
import AppButton from '@/components/AppButton.vue';
import AppCartAddRemove from '@/components/AppCartAddRemove.vue';
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  components: { AppButton, AppCartAddRemove },
  setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      //console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      //console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
  methods:{
      removeItem(item){
          this.$store.commit('addRemoveCart',{product:item,toAdd:false})
      },  
  },
};
</script>
    
<style scoped>
.cart-section {
  min-height: 100vh;
  padding: 5px;  
  margin-top: 50px;
  padding-top: 40px;
  background: #fff;
  width: 100%;
  /*border: 1px solid black;*/
  border-radius: 14px;
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.cart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card {
  width: 100%;
  background-color: #fff;
  border: none; 
}

.card-body {
  padding: 20px;
}

.cart-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-left {
  flex: 0 0 65%;
  padding-right: 20px;
}

.cart-header p {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.cart-item {
  background-color: #f9f9f9;
  margin-bottom: 15px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-info {
  display: flex;
  align-items: center;
}

.item-image {
  width: 65px;
  height: auto;
  border-radius: 5px;
  margin-right: 10px;
}

.item-quantity {
  flex: 1;
  text-align: center;
}

.item-price {
  text-align: right;
}

.discount-price {
  text-decoration: line-through;
  color: #999;
}

.remove-item-btn {
  color: #cecece;
  cursor: pointer;
  margin-left: 20px;
}

.cart-right {
  flex: 0 0 30%;
}

.cart-summary {
  background-color: #040404;
  color: white;
  padding: 20px;
  border-radius: 15px;
  margin-top: 30px;
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.summary-details, .summary-total {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.checkout-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.checkout-btn:hover {
  background-color: #218838;
}


.retro-table {
  margin: 0 auto;
  border-collapse: collapse;
}

.retro-table th,
.retro-table td {
  padding: 10px;
  border: 2px solid #000;
  background: white;
  justify-content: space-between; /* Adjust the space between cards */
}

.retro-table th {
  font-size: 20px;
  background-color:black;
  color:white;
}

.retro-table td {
  font-size: 14px;
}

.retro-table img {
  width: 80px;
}
/* Responsive */
@media (max-width: 768px) {
  .basket-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .basket-summary {
    width: 100%;
    padding: 10px 0;
  }
  /* Container for the product card */
.product-card {
  display: flex !important;
  align-items: flex-start !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* padding: 15px; */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Image section */
.product-image {
  flex: 1; /* Take 1 part of the flex container */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px; /* Set a fixed max width for the image */
}

.product-image img {
  width: 100%; /* Ensure image scales properly */
  height: auto;
  border-radius: 5px;
}

/* Details section */
.product-details {
  flex: 2; /* Take 2 parts of the flex container */
  padding-left: 15px;
}

/* Details section */
.product-details {
  flex: 2;
  padding-left: 15px;
}

.product-title {
  font-size: 1rem; /* Shrink title font size */
  font-weight: bold;
  margin-bottom: 5px;
}

.product-description {
  font-size: 0.8rem; /* Reduce font size for description */
  color: #666;
  margin-bottom: 10px;
}

.product-features {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 10px;
  color: #333;
  font-size: 0.8rem; /* Shrink font size for features */
}

/* Actions (buttons and price) */
.product-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.product-actions .btn {
  padding: 6px 10px; /* Shrink button size */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.8rem; /* Reduce font size for buttons */
}

.product-actions .btn:hover {
  background-color: #f0f0f0;
}

.product-actions .product-price {
  font-size: 1.2rem; /* Adjust font size for price */
  font-weight: bold;
  margin-left: auto;
}

}
</style>
    
