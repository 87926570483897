<template>
    <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
  <div class="container">
    <header class="header">
      <div class="left">
        <a :href="getDynamicUrl('/')">
          <img src="../assets/radar.svg" alt="logo" />
        </a>
        <h4 style="font-size: 1rem; color:black;">El Mapa del Mercado</h4>
      </div>
      <div class="divInput">
        <form class="nes-field" @submit.prevent="handleSearch">
          <input
            type="text"
            class="inpSearch"
            v-model="searchQuery"
            id="search-input"
            placeholder="¿Qué querés?"
          />
          <button type="submit" class="btnSearch fa fa-search" id="search-button"></button>
        </form>
      </div>
      <div class="right">
        <router-link :to="{ name: 'Cart' }" class="cart-icon">
          <i class="fa fa-shopping-cart"><p style="font-family: '3270'">CARRITO</p></i>
        </router-link>
        <img class="profile-icon" src="../assets/radarfavicon.svg" alt="Profile Icon" />
      </div>
    </header>
    <div class="subheader">
      <nav class="tabs">
        <ul>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/canasta')">Canasta</a>
          </li>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/finanzas')">Finanzas</a>
          </li>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/contacto')">Contacto</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  </section>
  <!-- MOBILE VERSION -->
  <section v-else>
    <header style="background: white;">
    <ul id="menu">
      <li class="logo-item">
        <a :href="getDynamicUrl('/')">
          <img class="logo-img" src="../assets/radarfavicon.svg" alt="logo" />
        </a>
      </li>
      <li class="search-item">
         <form class="nes-field" @submit.prevent="handleSearch">
          <input
            type="text"
            class="inpSearch"
            v-model="searchQuery"
            id="search-input"
            placeholder="¿Qué querés?"
          />
          <button type="submit" class="btnSearch fa fa-search" id="search-button"></button>
        </form>
      </li>
      <li class="cart-item">
         <router-link :to="{ name: 'Cart' }" class="cart-icon">
          <i class="fa fa-shopping-cart"></i>
        </router-link>
      </li>
    </ul>
    <div class="subheader">
      <nav>
         <ul>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/canasta')">Canasta</a>
          </li>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/finanzas')">Finanzas</a>
          </li>
          <li>
            <a class="nes-btn is-warning" :href="getDynamicUrl('/contacto')">Contacto</a>
          </li>
        </ul>
     </nav>
    </div>
  </header>
  </section>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'AppHeader',
  setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
  methods: {
    /**
     * Generates a dynamic URL based on the current origin and the provided path.
     * @param {string} path - The path to append to the current origin.
     * @returns {string} - The full dynamic URL.
     */
    getDynamicUrl(path) {
      return `${window.location.origin}${path}`;
    },
    /**
     * Handles the search form submission by redirecting to a dynamic URL.
     */
    handleSearch(event) {
      event.preventDefault(); // Prevent default form submission
      if (this.searchQuery) {
        const searchUrl = `${window.location.origin}/busqueda/${encodeURIComponent(this.searchQuery)}`;
        window.location.href = searchUrl; // Redirect to the generated URL
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/home.css";
@import "../assets/nes.min.css";
@font-face {
  font-family: "3270";
  src: url("@/assets/fonts/3270.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  position: relative;
  padding-top: 111px; /* Adjusted to include header and subheader height */
}

.header {
  width: 100%;
  height: 66px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between left and right elements */
  position: fixed;
  top: 0;
  z-index: 1000;
}
.subheader {
  width: 100%;
  height: 45px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 66px; /* Positions the subheader directly below the header */
  z-index: 999;
}

.left {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo-img {
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.divInput {
  flex-grow: 1;
  display: flexbox;
  justify-content: center;
  left: 40px;
  padding: 0 10px; /* Additional padding for breathing space */
}

.search-form {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.inpSearch {
  width: 70%;
  padding: 9px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 20px 0 0 20px;
  outline: none;
}

.btnSearch {
  padding: 12px 15px;
  border: none;
  border-radius: 0 20px 20px 0;
  background-color: #b6b1b1;
  cursor: pointer;
  font-size: 1rem;
}

.right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cart-icon,
.fas,
.profile-icon {
  cursor: pointer;
  font-size: 1.2rem;
}

.profile-icon {
  width: 30px;
  border-radius: 50%;
}

.tabs ul {
  display: flex;
  list-style: none;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.nes-btn {
  padding: 8px 16px;
  font-weight: bold;
}

/* RESPONSIVE-DESIGN */
@media screen  {
    /* Header Menu Styling */
  #menu {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    height: 60px;
    width: 100%;
    gap: 0; /* No space between elements */
}

/* Remove all padding and margins */
#menu > li {
  padding: 0;
  margin: 0;
}

/* Logo Item */
.logo-item {
  flex: 2; /* 20% of space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Search Box Item */
.search-item {
  flex: 4; /* 50% of space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 8px;
  border-radius: 0;
  border: 1px solid #ccc;
}

/* Cart Item */
.cart-item {
  flex: 2; /* 20% of space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-item a {
  color: black;
  font-size: 20px;
  text-decoration: none;
}

/* Ensure no gaps between flex items */
header,
ul#menu,
ul#menu > li {
  gap: 0;
  padding: 0;
  margin: 0;
  background-color: #fff; /* Solid, non-translucent background */
  color: white;
  z-index: 1000;
}
/* Subheader Styling */
.subheader {
  background: white;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Solid, non-translucent background */
  color: white;
  z-index: 1000;

}

.tabs ul {
  display: flex;
  list-style: none;
  gap: 15px;
  padding: 0;
  margin: 0;
}
/* Ensure Content Starts Below Header and Subheader */
.content {
  margin-top: 105px; /* Height of header (60px) + subheader (45px) */
  padding: 20px; /* Optional padding for content */
  z-index: 1000; /* Place it above other components */
}

}</style>
