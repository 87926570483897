<template>
    <button type="button" @click="addOrRemove()" class="basket">
        <i :class="toAdd ? 'icon-cart' : 'icon-cart-check'"></i>
    </button>
    <AppCartAddRemove v-if="!toAdd" :product="item" />
</template>

<script>
import AppCartAddRemove from './AppCartAddRemove.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    props: ['product'],
    components: { AppCartAddRemove },
    data() {
        return {
            toAdd: true,
            item: []
        };
    },
    methods: {
        async addOrRemove() {
            this.item.qty = 1;
            this.$store.commit('addRemoveCart', { product: this.item, toAdd: this.toAdd });
            let toasMSG;
            this.toAdd ? toasMSG = 'Agregado al Carrito' : toasMSG = 'Quitado del carrito';
            toast(toasMSG, {
                autoClose: 1000,
            });
            this.toAdd = !this.toAdd;
        }
    },
    mounted() {
        let cart = this.$store.state.cart;
        let obj = cart.find(o => o.id === this.product.id);
        if (obj) {
            this.toAdd = false;
            this.item = obj;
        } else {
            this.item = this.product;
            this.toAdd = true;
        }
    }
};
</script>

<style scoped>
.icon-cart::before {
    content: "AGREGAR"; /* Customize with the icon code from a font library or custom icon */
}

.icon-cart-check::before {
    content: "SACAR"; /* Customize with the icon code from a font library or custom icon */
}

/* Adjust the styles for the plus-minus input component */
.plus-minus input {
    max-width: 50px;
}

.basket {
    background: #167ce2;
    color: white;
    border: 4 px double #f7c31a;  
    display: inline;
    padding-top: 15px;
    padding: 5px; 
    width: 100%;
    z-index:100; 
  }
  .basket:hover { 
    animation: flicker 3s infinite alternate;    
  }

  /* Animate neon flicker */
  @keyframes flicker {
    0%, 19%, 22%, 62%, 64%, 70%, 100% {
      opacity: 0.99;
      text-shadow: 
        -1px -1px 0 rgba(255,255,255, 0.4), 
        1px -1px 0 rgba(255,255,255, 0.4), 
        -1px 1px 0 rgba(255,255,255, 0.4), 
        1px 1px 0 rgba(255,255,255, 0.4), 
        0 -2px 8px,
        0 0 2px,
        0 0 5px #ff7e00, 
        0 0 15px #ff4444, 
        0 0 2px #ff7e00, 
        0 2px 3px #000;
    }
    20%, 21%, 63%, 65%, 69.9% {
      opacity: 0.8;
      text-shadow: none;
    }
  }

  .card:hover .basket { 
    animation: flicker 3s infinite alternate;    
  }

</style>

