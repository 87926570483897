<template>
  <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
    <div>
      <section class="main-content clearfix">
        <div class="one">
          <h1>Canasta INDEC.</h1>
          <h4>Para una familia de 4 personas por un mes.</h4>
          <details>
            <summary>Lógica de la canasta. (HAZME CLICK)</summary>
            <p style="padding-top: 20px">
              <a
                href="https://www.indec.gob.ar/ftp/cuadros/sociedad/preguntas_frecuentes_cba_cbt.pdf"
                target="_blank"
                >Link Indec, ver página 14</a
              >
            </p>
            <p style="padding-bottom: 160px">
              La canasta búsca replicar el gasto promedio de los artículos que
              consume una familia. 4 personas durante 1 mes.
            </p>
          </details>
        </div>
        <div
          v-for="(item, index) in excel.slice(0, 1)"
          :key="item.id"
          class="two"
        >
          <h1>Barato $ {{ parseFloat(item.deal).toLocaleString(2) }}</h1>
          <h1>Caro $ {{ parseFloat(item.dumb).toLocaleString(2) }}</h1>
          <h4>Mira las opciones y chequea lo que ahorrarías,</h4>
          <h4>gracias a Radar.</h4>
        </div>
        <div class="three">
          <h1>Compara.</h1>
          <h4>
            Elegi por precio, minino-maximo, por cantidad u ofertas.
            <!-- <strong>Current route path:</strong> {{ $route.fullPath }} -->
          </h4>
        </div>
      </section>
      <section class="about-content">
        <!-- <h1>Resultados de la búsqueda.</h1> -->
        <h1 style="margin-top: 0px">
          Comparti
          <i
            style="margin-left: 20px"
            class="nes-icon whatsapp is-medium nes-pointer"
            @click="shareOnWhatsApp"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon twitter is-medium nes-pointer"
            @click="shareOnTwitter"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon instagram is-medium nes-pointer"
            @click="copyToClipboard"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon facebook is-medium nes-pointer"
            @click="shareOnFacebook"
          ></i>
        </h1>
        <!-- Section about Cards-Product display -->
        <section>
          <table class="retro-table">
            <thead>
              <tr>
                <th>Búsqueda</th>
                <th>Cantidad</th>
                <th>Imagen</th>
                <th>Producto</th>
                <th>Precio</th>
                <th></th>
                <th></th>
                <th></th>
                <!-- //FIX GAP column from excel => $(ExpPrice - price) -->
                <th>Brecha</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in excel" :key="item.id">
              <tr>
                <!-- <td><img :src="item.image" :alt="item.title" /> </td> -->
                <td>
                  <h1 style="font-size: 22px; padding: 0px">
                    {{ item.producto }}
                  </h1>
                </td>
                <td>{{ item.mes }}</td>
                <td>
                  <router-link :to="`/${item.id}`"
                    ><img :src="item.image" :alt="item.title"
                  /></router-link>
                </td>
                <td>{{ item.name }}</td>
                <td style="padding-left: 2px">
                  ${{ parseFloat(item.offer).toLocaleString(2) }}
                </td>
                <td><img :src="item.expimage" :alt="item.title" /></td>
                <td>{{ item.expname }}</td>
                <td>${{ parseFloat(item.expprice).toLocaleString(2) }}</td>
                <td>${{ parseFloat(item.gap).toLocaleString(2) }}</td>
                <td>
                  <h1 style="font-size: 18px; padding-bottom: 15px">
                    ${{ parseFloat(item.price).toLocaleString(2) }}
                  </h1>
                  <AppButton class="basket" :product="item" />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>
    </div>
  </section>
      <!-- MOBILE VERSION -->
      <section style="margin-top: 125px;margin-bottom: 125px;" v-else>
    <div>
      <h1>Canasta INDEC, para Familia de 4 personas por un mes</h1>
      <details>
        <summary>Lógica de la canasta. (HAZME CLICK)</summary>
        <p style="padding-top:20px;"><a href="https://www.indec.gob.ar/ftp/cuadros/sociedad/preguntas_frecuentes_cba_cbt.pdf" target="_blank">Link Indec, ver página 14</a></p>
        <p style="padding-bottom:160px;">
          La canasta búsca replicar el gasto promedio de los artículos que consume una familia.
            4 personas durante 1 mes.
        </p>
       </details>
    </div>
    <div v-for="(item, index) in excel.slice(0,1)" :key="item.id">
          <h1>Barato $ {{ parseFloat(item.deal).toLocaleString(2) }}</h1>
          <h1>Caro $   {{ parseFloat(item.dumb).toLocaleString(2) }}</h1>
          <h4>Mira las opciones y chequea lo que ahorrarías con Radar.</h4>
    </div>
      <div><h4 style="margin-top: 0px;text-align: center;">Comparti</h4>
      <i style="margin-left:25px;" class="nes-icon whatsapp  is-medium nes-pointer" @click="shareOnWhatsApp"></i>
      <i style="margin-left:25px;" class="nes-icon twitter   is-medium nes-pointer" @click="shareOnTwitter"></i>
      <i style="margin-left:25px;" class="nes-icon instagram is-medium nes-pointer" @click="copyToClipboard"></i>
      <i style="margin-left:25px;" class="nes-icon facebook  is-medium nes-pointer" @click="shareOnFacebook"></i>
      </div>
    <div  v-for="(item, index) in excel" :key="item.id" class="product-card"> <!-- DISPLAY TABLE AS GRID CARDS -->
      <div class="product-image">
        <router-link :to="`/${item.id}`"><img :src="item.image" :alt="item.title" /></router-link>
      </div>
      <div class="product-details">
        <h3 class="product-title">{{ item.producto }} X {{ item.mes }}</h3>
        <p class="product-description">
          {{ item.name }} <strong>{{ item.brand }}</strong>
        </p>
        <ul class="product-features">
          <li>de: {{ item.store }}.</li>
        </ul>
        <div class="product-actions">
          <span class="product-price">${{ parseFloat(item.price).toLocaleString(2) }} vs  ${{ parseFloat(item.expprice).toLocaleString(2) }}</span>
        </div>
        <AppButton class="basket" :product="item" />
      </div>
    </div>    
  </section>
</template>
  
  <script>
  import AppButton from "./AppButton.vue";
  import axios from "axios";
  import { BASE_URL } from "./config.js";
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    name: "AppBody",
    components: { AppButton },
    setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      //console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      //console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
    data() {
      return {
        excel: [],
      };
    },
    mounted() {
      this.getData();
    },
    methods: {
      getData() {
        const path = `${BASE_URL}/api/datahome`;
        axios
          .get(path)
          .then((response) => {
            this.excel = response.data.excel;
            this.excel = JSON.parse(this.excel);
            console.log(this.excel);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      },
      shareOnWhatsApp() {
        const currentUrl = window.location.href;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(whatsappUrl, "_blank");
      },
      shareOnTelegram() {
        const currentUrl = window.location.href; // Get the current URL
        const message = "Mira esto de Radar!"; // Your custom message
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(message)}`;
        window.open(telegramUrl, "_blank"); // Open the Telegram share dialog in a new tab
      },
      shareOnTwitter() {
        const currentUrl = window.location.href;
        const tweetText = "Mira esto de Radar!";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, "_blank");
      },
      copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert("Link copiado! Ahora puedes compartirlo ");
        });
      },
      shareOnFacebook() {
        const currentUrl = window.location.href; // Get the current URL
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(facebookUrl, "_blank");
      },
    },
  };
  </script>
  
  <style scoped>
  @import "../assets/home.css";
  @font-face {
    font-family: "3270";
    src: url("@/assets/fonts/3270.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
  .main-content,
  .about-content {
    background: #fff;
    width: 100%;
    padding: 20px;
    /*border: 1px solid black;*/
    border-radius: 14px;
    text-align: center;
  }
  .main-content {
    margin-top: 10px;
    height: 30px;
  }
  .one,
  .two,
  .three {
    width: 33.3%;
  }
  .one {
    float: left;
  }
  .two {
    float: center;
  }
  .three {
    float: right;
  }
  .about-content {
    margin-top: 50px;
    line-height: 1.3;
  }
  .basket {
    background: #eb3309;
    color: black;
    border: 4 px double #f7c31a;
    display: block;
    padding: 5px;
    width: 100%;
    height: 200px;
    z-index: 1000;
  }
  .basket:hover {
    animation: flicker 3s infinite alternate;
  }
  /* Animate neon flicker */
  @keyframes flicker {
    0%,
    19%,
    22%,
    62%,
    64%,
    70%,
    100% {
      opacity: 0.99;
      text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.4),
        1px -1px 0 rgba(255, 255, 255, 0.4), -1px 1px 0 rgba(255, 255, 255, 0.4),
        1px 1px 0 rgba(255, 255, 255, 0.4), 0 -2px 8px, 0 0 2px, 0 0 5px #ff7e00,
        0 0 15px #ff4444, 0 0 2px #ff7e00, 0 2px 3px #000;
    }
    20%,
    21%,
    63%,
    65%,
    69.9% {
      opacity: 0.8;
      text-shadow: none;
    }
  }
  
  .retro-table {
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .retro-table th,
  .retro-table td {
    padding: 1px;
    border: 2px solid #000;
    /* background-color: #fff; */
  }
  
  .retro-table th {
    font-size: 16px;
    background-color: black;
    color: white;
  }
  .retro-table tr:hover,
  .retro-table td:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(60, 179, 113);
  }
  
  .retro-table td {
    font-size: 14px;
  }
  
  .retro-table img {
    width: 80px;
  }
  .basket {
    background: #167ce2;
    color: white;
    border: 4 px double #f7c31a;
    display: block;
    padding: 5px 10px;
    width: 100%;
    z-index: 100;
  }
  /* Responsive */
@media (max-width: 768px) {
  .basket-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .basket-summary {
    width: 100%;
    padding: 10px 0;
  }
  /* Container for the product card */
.product-card {
  display: flex !important;
  align-items: flex-start !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* padding: 15px; */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Image section */
.product-image {
  flex: 1; /* Take 1 part of the flex container */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80px; /* Set a fixed max width for the image */
}

.product-image img {
  width: 100%; /* Ensure image scales properly */
  height: auto;
  border-radius: 5px;
}

/* Details section */
.product-details {
  flex: 2; /* Take 2 parts of the flex container */
  padding-left: 15px;
}

/* Details section */
.product-details {
  flex: 2;
  padding-left: 15px;
}

.product-title {
  font-size: 1rem; /* Shrink title font size */
  font-weight: bold;
  margin-bottom: 5px;
}

.product-description {
  font-size: 0.8rem; /* Reduce font size for description */
  color: #666;
  margin-bottom: 10px;
}

.product-features {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0 0 10px;
  color: #333;
  font-size: 0.8rem; /* Shrink font size for features */
}

/* Actions (buttons and price) */
.product-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.product-actions .btn {
  padding: 6px 10px; /* Shrink button size */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.8rem; /* Reduce font size for buttons */
}

.product-actions .btn:hover {
  background-color: #f0f0f0;
}

.product-actions .product-price {
  font-size: 1.2rem; /* Adjust font size for price */
  font-weight: bold;
  margin-left: auto;
}

}
  </style>
