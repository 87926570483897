<template>
  <div id="app">
    <AppContainer>
      <AppContact />
    </AppContainer>
  </div>
</template>

<script>
import AppContainer from "../components/AppContainer.vue";
import AppContact from "../components/AppContact.vue";

export default {
  name: "App",
  components: {
    AppContainer,
    AppContact,
  },
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

body {
  background: #ffff;
}
</style>
