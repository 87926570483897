<template>
  <!-- DESKTOP VERSION -->
  <section v-if="width > 786">
    <div>
      <section class="main-content clearfix">
        <h1>
          Comparti
          <i
            style="margin-left: 20px"
            class="nes-icon whatsapp is-medium nes-pointer"
            @click="shareOnWhatsApp"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon twitter is-medium nes-pointer"
            @click="shareOnTwitter"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon instagram is-medium nes-pointer"
            @click="copyToClipboard"
          ></i>
          <i
            style="margin-left: 20px"
            class="nes-icon facebook is-medium nes-pointer"
            @click="shareOnFacebook"
          ></i>
        </h1>
        <div class="lists">
          <ul
            style="padding: 30px; text-align: left"
            class="nes-container with-title is-centered"
          >
            <h1 style="font-size: 30px" class="title">¿Quienés Somos?</h1>
            <h1>
              ▲ Radar es un e-market. Una plataforma donde los agentes encuentran
              lo que quieren bajo criterio de su preferencia (precio u otro como
              volumen, calidad, categoria, etc). <br />Pueden comprar, vender y
              negociar entre las partes.
            </h1>
          </ul>
          <ul
            style="padding: 30px; text-align: left"
            class="nes-container is-dark with-title is-centered"
          >
            <h1 style="font-size: 30px" class="title">
              ¿Quierés sumarte a nuestro equipo?
            </h1>
            <h1 style="text-align: center">
              Mandanos un email a ==>
              <i
                style="margin-left: 20px"
                class="nes-icon gmail is-medium nes-pointer"
                @click="sentToGmail"
              ></i>
            </h1>
          </ul>
          <ul
            style="padding: 30px; text-align: left"
            class="nes-container with-title is-centered"
          >
            <h1 style="font-size: 30px" class="title">
              ¿Quierés invertir en RADAR?
            </h1>
            <h1 style="text-align: center">
              Evaluamos ofertas. Mandanos un email.
            </h1>
          </ul>
          <ul
            style="padding: 30px; text-align: left"
            class="nes-container is-dark with-title is-centered"
          >
            <h1 style="font-size: 30px" class="title">Avances</h1>
            <h1 style="text-align: center">
              ▲ Permitir crear perfiles de tiendas para subir su catalogo.
            </h1>
          </ul>
        </div>
      </section>
    </div>
</section>
<!-- MOBILE VERSION -->
<section style="margin-top: 125px;" v-else>
    <div class="lists">
        <ul style="padding:30px; text-align: left;" class="nes-container with-title is-centered">
          <h1 style="font-size: 30px;"  class="title">¿Quienés Somos?</h1>
          <h1>▲ Radar es un e-market. Una plataforma donde los agentes encuentran lo que quieren bajo criterio de su preferencia (precio u otro como volumen, calidad, categoria, etc).
          <br>Pueden comprar, vender y negociar entre las partes.</h1>
        </ul>
        <ul style="padding:30px; text-align: left;" class="nes-container is-dark with-title is-centered">
          <h1 style="font-size: 30px;" class="title">¿Quierés sumarte a nuestro equipo?</h1>
          <h1 style="text-align:center;">Mandanos un email a ==> <i style="margin-left:20px;" class="nes-icon gmail is-medium nes-pointer"  @click="sentToGmail"></i></h1>
        </ul>
        <ul style="padding:30px; text-align: left;" class="nes-container with-title is-centered">
          <h1 style="font-size: 30px;" class="title">¿Quierés invertir en RADAR?</h1>
          <h1 style="text-align:center;">Evaluamos ofertas. Mandanos un email.</h1>
        </ul>
        <ul style="padding:30px; text-align: left;" class="nes-container is-dark with-title is-centered">
          <h1 style="font-size: 30px;" class="title">Avances</h1>
          <h1 style="text-align:center;">▲ Permitir crear perfiles de tiendas para subir su catalogo.</h1>
        </ul>
    </div>
  </section>  
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'AppContact',
  setup() {
    const width = ref(0); // Initialize with 0 to avoid any carryover issues

    const updateWidth = () => {
      const currentWidth = window.innerWidth; // Store in a local variable
      console.log('window.innerWidth:', currentWidth); // Log directly
      width.value = currentWidth;
      console.log('Updated width value:', width.value);
    };

    onMounted(() => {
      updateWidth(); // Initial check
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      width,
    };
  },
    methods: {
      shareOnWhatsApp() {
        const currentUrl = window.location.href;
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentUrl
        )}`;
        window.open(whatsappUrl, "_blank");
      },
      shareOnTwitter() {
        const currentUrl = window.location.href;
        const tweetText = "Miren esto! de @radars_app";
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentUrl
        )}&text=${encodeURIComponent(tweetText)}`;
        window.open(twitterUrl, "_blank");
      },
      copyToClipboard() {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
          alert("Link copiado! Ahora puedes compartirlo");
        });
      },
      shareOnFacebook() {
        const currentUrl = window.location.href;
        const facebookUrl = `https://www.facebook.com/sharer.php?u=${currentUrl}`;
        window.open(facebookUrl, "_blank");
      },
      sentToGmail() {
        const emailAddress = "radar@radarsapp.com";
        const subject = "Quiero Sumarme"; // You can customize this
        const body = "Message Body Here"; // You can customize this
        const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
          emailAddress
        )}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(gmailUrl, "_blank"); // Opens in a new tab
      },
    },
  };
  </script>
  
  <style scoped>
  @import '../assets/home.css';
  @import '../assets/nes.min.css';
  @font-face {
      font-family: '3270';
      src: url('@/assets/fonts/3270.otf') format('opentype');
      font-weight: normal;
      font-style: normal;
    }
  
    .main-content, .about-content {
      background: #fff; 
      width: 100%;
      min-width: 80%;
      padding: 20px;
      display: block;
      padding-top: 50px;
      padding-bottom: 450px;
      /* border: 1px solid black; */
      border-radius: 14px;
      text-align: center;
      z-index: 1000;
    }
    .main-content {
      display: block;
      margin-top: 5px;
      height: 60px;
      min-width: 60%;
    }
    .one, .two, .three {
      width: 33.3%;
    }
    .one {
      float: left;
    }
    .two {
      float: center;
    }
    .three {
      float: right;
    }
    .about-content {
      margin-top: 10px;
      line-height: 1.3;
    }
    
  .picture {
    margin: 3px auto;
    border: 1px solid black;
    border-radius: 50%;
  }
  
   </style>
